<template>
  <v-card
    c
    flat
    outline
    color="grey lighten-1"
    class="checkout-summary pa-3 pb-2 rounded-md"
  >
    <div class="mb-3 text-body-1 font-weight-bold text-uppercase">
      {{ $t(title) }}
    </div>
    <v-card-text class="summary-text pa-0">
      <div
        class="d-flex justify-space-between align-center font-weight-bold mb-1"
      >
        <span>{{ $t("cartSummary.totalPriceCheckout") }}</span>
        <span>
          {{
            $n(orderCart.deliveredNetTotal || orderCart.totalPrice, "currency")
          }}
        </span>
      </div>
      <!-- totalPriceVariable -->
      <div
        class="d-flex justify-space-between align-center text-body-2 font-italic mb-1"
        v-if="orderCart.totalPriceVariable > 0"
      >
        <span>{{ $t("checkout.summary.itemsTotalByWeight") }}</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.shippingTotal > 0"
      >
        <span>{{ $t("checkout.summary.shippingTotal") }}</span>
        <span>{{ $n(orderCart.shippingTotal, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.deliveryFee > 0"
      >
        <span>{{ deliveryFeeTitle }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-for="(orderPackage, index) in orderPackages"
        :key="index + '_package'"
      >
        <span
          >{{ orderPackage.package.descr }} <br />({{
            orderPackage.quantity
          }}&nbsp;x&nbsp;{{ $n(orderPackage.package.cost, "currency") }})</span
        >
        <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2 font-weight-bold mb-1 mt-4"
        v-if="orderCart.packageTotal > 0"
      >
        <span>{{ $t("checkout.summary.packageTotal") }}</span>
        <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
      </div>

      <div
        class="gift-certificates-to-generate d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
        v-for="(gift, index) in orderCart.giftCertificatesToGenerate"
        :key="index"
      >
        <span class="mr-8">{{ gift.description }}</span>
        <span v-if="gift.amount > 0 || gift.total > 0">
          <!-- {{
            gift.description.indexOf("%") > -1
              ? gift.amount + "&nbsp;%"
              : $n(gift.total, "currency")
          }} -->
          {{
            !(gift.amount && gift.amount >= 0) && gift.total > 0
              ? $n(gift.total, "currency")
              : ""
          }}
        </span>
      </div>

      <div
        v-for="(gift, index) in orderCart.giftCertificates"
        :key="index + '_gift'"
      >
        <div
          class="gift-certificates d-flex flex-nowrap justify-space-between align-center text-body-2 mt-4"
          v-if="gift.amount > 0 && !(gift.total > 0)"
        >
          <span class="mr-8">{{ gift.description }}</span>
          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            >{{
              gift.description.indexOf("%") > -1
                ? gift.amount + "&nbsp;%"
                : $n(gift.amount, "currency")
            }}
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCode(gift.userGiftCertificateId)"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
        <div
          class="d-flex flex-nowrap justify-space-between align-center text-body-2 w-100 mt-4"
          v-else-if="gift.total > 0"
        >
          <span class="mr-8">{{ gift.description }}</span>
          <span
            v-if="(gift.amount > 0 || gift.total > 0) && canDeleteGift"
            class="d-flex flex-nowrap align-center"
            >{{ gift.total > 0 ? $n(gift.total, "currency") : "" }}
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="removeGiftCode(gift.userGiftCertificateId)"
                  class="remove-gift"
                  ><v-icon color="primary" small>
                    $clear
                  </v-icon></v-btn
                >
              </template>
              <span>{{ $t("checkout.giftCodeRemoveTooltip") }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2 mb-1 font-weight-bold mt-2"
        v-if="orderCart.giftTotal > 0"
      >
        <span>{{ $t("checkout.summary.giftTotal") }}</span>
        <span>{{ $n(orderCart.giftTotal, "currency") }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="orderCart.refundTotal > 0"
      >
        <span>{{ $t("checkout.summary.refundTotal") }}</span>
        <span>{{ $n(orderCart.refundTotal, "currency") }}</span>
      </div>

      <v-divider class="my-3"></v-divider>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['98000002'] &&
            orderCart.userPoints['98000002'].value > 0 &&
            orderCart.user.fidelityCard
        "
      >
        <span class="font-weight-bold">{{
          $t("checkout.summary.accumulate")
        }}</span>
        <span>{{ orderCart.userPoints["98000002"].value }} punti</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['90000528'] &&
            orderCart.userPoints['90000528'].value > 0
        "
      >
        <span>{{ $t("checkout.summary.cashback") }}</span>
        <span>{{
          $n(orderCart.userPoints["90000528"].value / 100, "currency")
        }}</span>
      </div>

      <div
        class="d-flex justify-space-between align-center text-body-2  mb-1"
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['86000051'] &&
            orderCart.userPoints['86000051'].value > 0
        "
      >
        <span>{{ $t("checkout.summary.cashbackToSpend") }}</span>
        <span>{{
          $n(orderCart.userPoints["86000051"].value / 100, "currency")
        }}</span>
      </div>

      <div
        v-if="
          orderCart.userPoints &&
            orderCart.userPoints['BOLL'] &&
            orderCart.userPoints['BOLL'].value > 0
        "
        class="d-flex justify-space-between align-center text-body-2 mt-4"
      >
        <span class="font-weight-bold">{{ $t("checkout.summary.earn") }}</span>
        <span
          >{{ orderCart.userPoints["BOLL"].value }}&nbsp;
          {{ $t("checkout.summary.bollini") }}
        </span>
      </div>

      <div
        class="collezionamento-title font-weight-bold mt-4"
        v-if="filteredOrderPackages && filteredOrderPackages.length > 0"
      >
        {{ $t("checkout.summary.sheets") }}
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-2 mt-4 pb-1"
        v-for="(orderPackage, index) in filteredOrderPackages"
        :key="index - '_orderPackage'"
      >
        <span>{{ orderPackage.package.descr }}</span>
        <span>{{ orderPackage.quantity }}</span>
      </div>

      <div class="d-flex justify-space-between align-center mt-4">
        <span class="text-h4">{{ $t("checkout.summary.total") }}</span>
        <span class="text-h3">
          <OrderGrossTotal
            :item="orderCart"
            :delivered="delivered"
          ></OrderGrossTotal>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss"></style>
<script>
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";

export default {
  name: "CheckoutSummary",
  components: { OrderGrossTotal },
  props: {
    orderCart: { type: Object, required: true },
    delivered: { type: Boolean, default: false },
    title: { type: String, default: "checkout.summary.cart" },
    canDeleteGift: { type: Boolean, default: true }
  },
  computed: {
    saved() {
      return (
        this.orderCart.orderAdjustmentSummary?.TYPE_ITEM_TOTAL.totalVariation +
        this.orderCart.orderAdjustmentSummary?.TYPE_NET_TOTAL.totalVariation
      );
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    deliveryFeeTitle() {
      return this.$t(
        "checkout.summary.deliveryFeeTitle." +
          this.orderCart.shippingAddress.addressType
      );
    },
    orderPackages() {
      // real sacchetti
      return this.orderCart.orderPackages
        ? this.orderCart.orderPackages.filter(
            item => item.package.packageTypeId !== 30
          )
        : [];
    },
    filteredOrderPackages() {
      // used for collezionamento/scarico punti
      return this.orderCart.orderPackages
        ? this.orderCart.orderPackages.filter(
            orderPackage => orderPackage.package.packageTypeId === 30
          )
        : [];
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    }
  }
};
</script>
